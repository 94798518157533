$(document).ready((function () {
    const e = $(".image-carousel"), t = $(".video-carousel");
    let o = $(window).width();
    e.owlCarousel({
        loop: !0,
        margin: 50,
        dots: !0,
        lazyLoad: !0,
        touchDrag: o < 1024,
        mouseDrag: o < 1024,
        center: o < 1024,
        items: 1,
        responsive: {0: {items: 1}, 1200: {items: 3}}
    }), t.owlCarousel({
        loop: !1,
        dots: !0,
        dotsEach: !0,
        lazyLoad: !0,
        margin: o > 1024 ? 50 : o > 576 ? 40 : o > 413 ? 20 : 0,
        stagePadding: o < 1024 && o > 414 ? 60 : 0,
        center: o < 1024,
        autoWidth: o < 1024,
        items: 1,
        responsive: {0: {items: 1}, 1200: {items: 2}}
    }), $(".customNextBtn").click((function () {
        e.trigger("next.owl.carousel", [300])
    })), $(".customPrevBtn").click((function () {
        e.trigger("prev.owl.carousel", [300])
    })), $(".customVideoNextBtn").click((function () {
        t.trigger("next.owl.carousel", [300])
    })), $(".customVideoPrevBtn").click((function () {
        t.trigger("prev.owl.carousel", [300])
    })), $(window).on("resize", (function () {
        $(this).width() !== o && location.reload()
    }))
}));
const e = [["goToWhatWeDo", "what-we-do"], ["goToHowItWorks", "how-it-works"], ["goToCalculator", "calculator"], ["goToPrices", "prices"], ["bodyGoToCalculator", "calculator"]],
    t = () => {
        for (const [t, o] of e) {
            const e = document.getElementById(o), n = document.getElementById(t);
            n?.addEventListener("click", (() => e?.scrollIntoView({behavior: "smooth"})))
        }
    };
document.addEventListener("DOMContentLoaded", (() => {
    const e = document.querySelector(".nav-list"), o = document.getElementById("openMenu"),
        n = document.getElementById("closeMenu");
    o.addEventListener("click", (() => {
        e.classList.add("open"), o.classList.add("display-none"), n.classList.remove("display-none"), t()
    })), n.addEventListener("click", (() => {
        e.classList.remove("open"), n.classList.add("display-none"), o.classList.remove("display-none")
    }));
    const c = document.querySelectorAll(".nav-list li a"), s = () => {
        e.classList.remove("open"), n.classList.add("display-none"), o.classList.remove("display-none")
    };
    c.forEach((e => {
        e.addEventListener("click", s)
    }));
    const a = document.querySelectorAll(".nav-list a");
    a.forEach((e => {
        e.addEventListener("click", (e => () => {
            a.forEach((e => {
                e.classList.remove("active")
            })), e.classList.add("active")
        })(e))
    }));
    const i = document.querySelector(".money-range-label .value"), l = document.getElementById("money-range"),
        r = document.querySelector(".month-range-label .value"), d = document.getElementById("month-range");
    l.addEventListener("input", (e => {
        i.innerText = e.target.value
    }), !0), d.addEventListener("input", (e => {
        r.innerText = e.target.value
    }), !0);
    const u = document.querySelectorAll(".faq .item");
    u.forEach((e => {
        e.getElementsByClassName("question")[0].addEventListener("click", (() => (e => {
            e.classList.contains("active") ? e.classList.remove("active") : u.forEach((t => {
                t.classList.remove("active"), e.classList.add("active")
            }))
        })(e)))
    }));
    let m = "2021";
    const v = document.querySelectorAll("input[name='year']"), g = document.querySelector(".form-submit"),
        y = document.querySelector(".recover-summ .recover-summ__value .number"),
        L = document.querySelector(".recover-summ");
    v.forEach((e => {
        e.addEventListener("click", (() => (e => {
            m = e.value
        })(e)))
    })), g.addEventListener("click", (function (e) {
        e.preventDefault();
        let t = .113;
        switch (m) {
            case"2021":
                t = .113;
                break;
            case"2022":
                t = .1069
                break;
            case"2023":
                t = .104
                break;
            case"2024":
                t = .1019
                break;
        }
        y.innerText = (i.textContent * r.textContent * t).toFixed(0), L.classList.add("show"), L.scrollIntoView({behavior: "smooth"})
    }), !1);
    $(".play-video").on("click", (e => {
        e.target.style.visibility = "hidden", e.target.nextElementSibling.contentWindow.postMessage(JSON.stringify({
            event: "command",
            func: "playVideo"
        }), "*")
    }));
    const E = document.getElementById("mobile-fix-item");
    window.innerWidth > 1024 && E.remove(), t()
}));

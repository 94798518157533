$(document).ready(function () {
  const imgCarousel = $(".image-carousel");
  const videoCarousel = $(".video-carousel");
  let windowWidth = $(window).width();

  imgCarousel.owlCarousel({
    loop: true,
    margin: 50,
    dots: true,
    lazyLoad: true,
    touchDrag: windowWidth < 1024,
    mouseDrag: windowWidth < 1024,
    center: windowWidth < 1024,
    items: 1,
    responsive: {
      0: {
        items: 1,
      },

      1200: {
        items: 3,
      },
    },
  });

  videoCarousel.owlCarousel({
    loop: false,
    dots: true,
    dotsEach: true,
    lazyLoad: true,
    margin:
      windowWidth > 1024
        ? 50
        : windowWidth > 576
        ? 40
        : windowWidth > 413
        ? 20
        : 0,
    stagePadding: windowWidth < 1024 ? (windowWidth > 414 ? 60 : 0) : 0,
    center: windowWidth < 1024,
    autoWidth: windowWidth < 1024,
    items: 1,
    responsive: {
      0: {
        items: 1,
      },

      1200: {
        items: 2,
      },
    },
  });

  $(".customNextBtn").click(function () {
    // With optional speed parameter
    // Parameters has to be in square bracket '[]'
    imgCarousel.trigger("next.owl.carousel", [300]);
  });

  $(".customPrevBtn").click(function () {
    imgCarousel.trigger("prev.owl.carousel", [300]);
  });

  $(".customVideoNextBtn").click(function () {
    videoCarousel.trigger("next.owl.carousel", [300]);
  });

  $(".customVideoPrevBtn").click(function () {
    videoCarousel.trigger("prev.owl.carousel", [300]);
  });

  $(window).on("resize", function () {
    if ($(this).width() !== windowWidth) {
      location.reload();
    }
  });
});

const navigationItems = [
  ["goToWhatWeDo", "what-we-do"],
  ["goToHowItWorks", "how-it-works"],
  ["goToCalculator", "calculator"],
  ["goToPrices", "prices"],
  ["bodyGoToCalculator", "calculator"],
];

const initNavigationActions = () => {
  for (const [actionId, targetId] of navigationItems) {
    const target = document.getElementById(targetId);
    const action = document.getElementById(actionId);

    action?.addEventListener("click", () =>
      target?.scrollIntoView({ behavior: "smooth" })
    );
  }
};

document.addEventListener("DOMContentLoaded", () => {
  const menuList = document.querySelector(".nav-list");
  const openMenuIcon = document.getElementById("openMenu");
  const closeMenuIcon = document.getElementById("closeMenu");

  openMenuIcon.addEventListener("click", () => {
    menuList.classList.add("open");
    openMenuIcon.classList.add("display-none");
    closeMenuIcon.classList.remove("display-none");

    initNavigationActions();
  });

  closeMenuIcon.addEventListener("click", () => {
    menuList.classList.remove("open");
    closeMenuIcon.classList.add("display-none");
    openMenuIcon.classList.remove("display-none");
  });

  const menuItems = document.querySelectorAll(".nav-list li a");

  const handleClickMenuItem = () => {
    menuList.classList.remove("open");
    closeMenuIcon.classList.add("display-none");
    openMenuIcon.classList.remove("display-none");
  };

  menuItems.forEach((item) => {
    item.addEventListener("click", handleClickMenuItem);
  });

  // Add active class to nav items on click
  const navItems = document.querySelectorAll(".nav-list a");

  const handleNavItemsClick = (item) => () => {
    navItems.forEach((item) => {
      item.classList.remove("active");
    });

    item.classList.add("active");
  };

  navItems.forEach((item) => {
    item.addEventListener("click", handleNavItemsClick(item));
  });

  const moneyValue = document.querySelector(".money-range-label .value");
  const moneyRange = document.getElementById("money-range");
  const monthValue = document.querySelector(".month-range-label .value");
  const monthRange = document.getElementById("month-range");

  moneyRange.addEventListener(
    "input",
    (e) => {
      moneyValue.innerText = e.target.value;
    },
    true
  );

  monthRange.addEventListener(
    "input",
    (e) => {
      monthValue.innerText = e.target.value;
    },
    true
  );

  // FAQ

  const faqItem = document.querySelectorAll(".faq .item");

  const handleItemQuestionClick = (item) => {
    item.classList.contains("active")
      ? item.classList.remove("active")
      : faqItem.forEach((newItem) => {
          newItem.classList.remove("active");
          item.classList.add("active");
        });
  };

  faqItem.forEach((item) => {
    const itemQuestion = item.getElementsByClassName("question")[0];

    itemQuestion.addEventListener("click", () => handleItemQuestionClick(item));
  });

  // Calculator Form

  let selectedYear = "2019";
  const years = document.querySelectorAll("input[name='year']");
  const formSubmitBtn = document.querySelector(".form-submit");
  const recoveryValue = document.querySelector(
    ".recover-summ .recover-summ__value .number"
  );
  const recoverSummContainer = document.querySelector(".recover-summ");

  const handleYearSelection = (item) => {
    selectedYear = item.value;
  };

  years.forEach((item) => {
    item.addEventListener("click", () => handleYearSelection(item));
  });

  formSubmitBtn.addEventListener("click", handleFormSubmit, false);

  function handleFormSubmit(e) {
    e.preventDefault();

    let vat = 0.12;

    switch (selectedYear) {
      case "2019":
        vat = 0.12;
        break;
      case "2020":
        vat = 0.115;
        break;
      case "2021":
        vat = 0.113;
        break;
      case "2022":
        vat = 0.1069;
        break;
    }

    recoveryValue.innerText = (
      moneyValue.textContent *
      monthValue.textContent *
      vat
    ).toFixed(0);

    recoverSummContainer.classList.add("show");
    recoverSummContainer.scrollIntoView({ behavior: "smooth" });
  }

  // Video feedback

  const playVideoBtn = $(".play-video");

  playVideoBtn.on("click", (e) => {
    e.target.style.visibility = "hidden";

    e.target.nextElementSibling.contentWindow.postMessage(
      JSON.stringify({ event: "command", func: "playVideo" }),
      "*"
    );
  });

  const mobileItem = document.getElementById("mobile-fix-item");
  let windowWidth = window.innerWidth;

  if (windowWidth > 1024) {
    mobileItem.remove();
  }

  initNavigationActions();
});
